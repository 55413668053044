@import "src/styles/colors";
@import "src/styles/breakpoints";

.job-hired {

	&__filters {
		margin: 24px 0;
		display: flex;

		.input-default-wrapper {
			margin-right: 32px;
			padding: 0;
			width: 312px;
		}

		.candidate-status {
			margin-bottom: 0;
			margin-left: auto;
			width: 262px;
		}
	}

	&__table {
		&-cell {
			.typography {
				color: $main;
				font-size: 14px;
				line-height: 16px;
				&.subtitle {
					margin-top: 2px;
					color: $placeholder;
					font-size: 12px;
				}
			}
			&__link {
				.typography {
					text-overflow: ellipsis;
					overflow: hidden;
				}
				&:hover .typography {
					color: $link;
				}
			}
		}
		&_empty {
			height: 420px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			position: relative;

			.empty-image {
				height: 124px;
				margin-bottom: 40px;
				width: 124px;
			}

			&__loading {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(245, 247, 250, 0.7);
			}
		}
	}

	&__list {
		display: none;
	}

	@media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
		&__filters {
			margin: 16px 0;
			flex-direction: column;
			position: relative;

			.checkbox-default {
				margin-top: 16px;
			}

			.candidate-status {
				position: absolute;
				right: 0;
			}
		}

		&__table {
			.ant-table-content table {
				width: 1144px !important;
			}
			&_empty {
				height: 420px;
			}
		}
	}

	@media only screen and (max-width: $screen-mobile-max) {
		&__filters {
			margin: 16px 0;
			padding: 0 16px;
			flex-direction: column;
			align-items: flex-start;
			.input-default-wrapper {
				margin: 0;
				width: 100%;
			}
			.checkbox-default {
				margin-top: 12px
			}

			.candidate-status {
				margin-top: 12px;
				width: 100%;
			}
		}
		&__table {
			display: none;
			&_empty {
				border-radius: 0;
				box-shadow: none;
			}
		}

		&__list {
			display: block;
			border-radius: 0;

			.header {
				padding: 20px 16px 16px;

				.typography-h2 {
					color: $black;

					span {
						margin-left: 4px;
						color: $lightgray;
					}
				}
			}

			.content {
				.hired-item {
					padding: 16px;
					border-top: 1px solid $border;

					&__action {
						margin-bottom: 8px;
						display: flex;
						justify-content: flex-end;
					}

					.row {
						display: flex;
						&:not(:last-child) {
							margin-bottom: 8px;
						}
						.label {
							margin-right: 8px;
							width: 120px;
							flex: 0 0 120px;
						}
						a {
							.typography-text {
								color: $main;
							}
							.typography-caption {
								margin-top: 4px;
								color: $placeholder;
							}
							&:hover {
								.typography-text,
								.typography-caption {
									color: $link;
								}
							}
						}
					};
				}
			}
		}
	}
}