@import "../../styles/colors";
@import "../../styles/breakpoints";

.job-candidate-profile {
  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &.actions {
      padding-right: 52px;
    }

    .candidate-info {
      display: flex;

      .candidate-name {
        &__latin {
          display: block;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: $main;
        }

        &__local {
          margin-top: 4px;
          color: $lightgray;
        }
      }

      .status-default {
        margin-left: 12px;
      }
    }

    .extra-actions {
      position: absolute;
      right: 0;
    }
  }

  .title {
    margin-bottom: 24px !important;
    position: relative;

    &.contact-info {
      cursor: pointer;
      .vector {
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(-90deg);
      }

      &.open .vector {
        transform: rotate(90deg);
      }
    }

    .typography-h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #393F4A;
    }
  }

  .network-links a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 4px;
    position: relative;

    span:not(.network) {
      max-width: calc(100% - 100px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .network {
      color: #181B21;
      position: absolute;
      right: 0;
    }
  }

  &__body {

    .info-item {
      margin-bottom: 16px;

      .typography-text > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }


      .cv-link {
        margin-top: 8px;
        display: flex;
        align-items: center;
        .typography {
          color: $link;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__icon {
          flex: 0 0 16px;
          display: block;
          margin-right: 12px;
          width: 16px;
          height: 16px;
          path {
            fill: #717C8C;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &.foreign-languages {
        .typography-text {
          margin-top: 8px;
        }
      }

      .relocation-item {
        margin-top: 8px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }
      }

      .file-default {
        margin-top: 8px;
      }
    }
    .typography {
      color: $main;

      &-label {
        margin-bottom: 8px;
      }
      &-caption {
        color: $lightgray;
      }
    }
  }

  .ant-divider {
    margin: 32px 0;
  }

  &__reassign {
    margin-left: 44px;
    padding: 0;
    height: unset;
    background-color: $white !important;
    border-color: transparent !important;
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }
  @media only screen and (max-width: $screen-mobile-max) {

    &__header {
      flex-direction: column;

      .candidate-info {
        margin-bottom: 16px;
        flex-direction: column;

        .status-default {
          margin: 4px 0 0;
        }
      }

      .submit-decision {
        width: max-content;
      }
    }
  }
}