@import "../../styles/colors";
@import "../../styles/breakpoints";

.right-panel {
  padding: 24px;
  width: 752px;

  &__empty {
    padding: 80px 24px;
    height: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }

    .typography-h3 {
      margin-top: 16px;
    }
    .typography-text {
      margin-top: 8px;
    }
  }

  .approvers-candidate{
    &.openings{
      .title{
        margin-bottom: 16px;
      }
    }
  }
  .scorecard-wrapper_extra{
    margin-top: 30px;
    &.show-content{
      margin-top: 10px;
    }

  }

  .roles-wrapper{
    margin-top: 5px;
    display: flex;
    .ats-label{
      margin-right: 10px;
    }
  }

  &_detailsCandidates{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;


    .candidateInfo {
      display: flex;
      &.information{
        .info-content {
          .baseInfo {
            .job {
              color: #707E8B;
              margin-top: 4px;
            }
            .name{
              &:hover{
                color: $link;
              }
            }
          }
          .extraInfo{
            .extraInfo_list{
              li{
                color: #707E8B;
              }
              a{
                color: #707E8B;
                text-decoration: underline;
                text-underline-position: under;
              }
              a:hover{
                color:$accent_70;
                text-decoration: none;
              }
            }
          }

          .responsible_recruiter-wrapper{
            display: flex;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            margin-top: 2px;
            .responsible_recruiter {
              color: #707E8B;
            }
            .change, .assign{
              color: #FB5830;
              text-decoration: underline;
              text-underline-position: under;
              margin-left: 4px;
              &:hover{
                cursor: pointer;
                text-decoration: none;
              }
            }
            .assign{

            }
          }
        }
      }
      .avatar {
        display: inline-block;
        margin-right: 16px;
        margin-bottom: 10px;
      }
      .info-content {
        .baseInfo {
          margin-top: 8px;
          .name {
            text-decoration: none;
            font-size: 22px;
            color: $black;
            line-height: 26px;
            cursor: pointer;
          }

          .job {
            font-size: 15px;
            line-height: 18px;
            color: #757575;
          }
        }
        .extraInfo{
          display: flex;
          margin-top: 24px;

          .icon{
            display: inline-block;
            margin-right: 17px;
            position: relative;
            top: 1px;
            width: 15px;
            text-align: center;
          }

          &_list{
            padding-left: 0;
            margin: 0 25px 0 0;

            .lighted {
              color: #FB5830;

              a {
                color: #FB5830;
                text-decoration: none;
              }
            }

            &_option{
              display: flex;
              margin-bottom: 13px;
              font-size: 15px;
              line-height: 18px;
              color: #414549;
            }
          }
        }
      }
    }

    &_actions-container {
      display: flex;
      position: absolute;
      right: 0;
      white-space: nowrap;
    }
    .button-menu-default {
      margin-left: 20px;
      ul {
        z-index: 6;
      }
    }
  }

  .cvs-candidates{
    margin-top: 17px;
    &.steps{
      .cv-information{
        padding: 16px 24px 16px 0;
        .container-info{
          width:100%;
        }
        .mainInfo{
          a:hover{
            text-decoration: underline;
          }
        }
        .buttons-item{
          font-size: 13px;
        }
      }
    }
    .title{
      margin-bottom: 16px;
    }
  }
  .add-component{
    .title{
      margin-bottom: 4px;
    }
    .add-button{
      margin-top: 16px;
    }
  }
  .approvers-candidate{
    .approvers-candidate_subtitle{
      color: #707E8B;
    }
    margin-top: 30px;
    &.items{
      .title {
        display: flex;
        justify-content: space-between;
        .add-approver-btn{
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #FB5830;
          &:hover{
            cursor: pointer;
          }

        }
      }
    }

    .candidates-content {
      margin-top: 21px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .approver {
        margin-top: 0;
        width: 50%;
      }
      .approvers-candidate_subtitle{
        margin-top: -16px;
      }
    }

    &.feed {
      margin: 0;
      h3{
        margin-bottom: 16px;
      }
    }

    .title {
      margin-bottom: 8px;
    }

    &_subtitle {
      font-size: 15px;
      line-height: 18px;
      color: #474747;
    }

    .approver {
      margin-top: 20px;
      position: relative;
      display: flex;
      height: 66px;
      align-items: center;
      padding-left: 14px;
      border-radius: $border_radius;

      &_menu{
        display: none;
      }
      &:hover{
        background-color: #F5F6F7;

        .approver_menu{
          top: 11px;
          right: 10px;
          display: block;
        }
      }

      &:first-child{
        margin-top: 0;
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    width: 100%;

    &__empty {
      width: 100%;
    }

    .activity-feed {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    width: 100%;
    padding: 24px 16px;

    &__empty {
      margin: 0 16px;
      width: unset;
    }
  }
}

.activity-feed {
  max-width: 752px;

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
    max-width: 100%;
  }

  @media only screen and (max-width: $screen-mobile-max) {
    max-width: 100%;
  }
}