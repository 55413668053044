@import "src/styles/colors";
@import "src/styles/breakpoints";

.new-jobs {
  margin-bottom: 0;
  width: 556px;
  height: 264px;

  &__header {
    padding: 24px;
    border-bottom: 1px solid $border;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    .number {
      margin-left: 8px;
      color: #97A3B5;
    }
  }

  &__content {
    height: calc(100% - 73px);
    padding: 16px 4px 0 24px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px !important;
      border: 6px solid white !important;
      background-color: $disabled_bg;
      border-radius: 9999px !important;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 9999px !important;
    }

    &::-webkit-scrollbar-thumb {
      cursor: default !important;
      min-height: 40px;
      background-color: $border;
      border: 6px solid transparent !important;
      background-clip: padding-box;
      border-radius: 9999px !important;
    }

    &-loading {
      padding: 24px;
    }
    &-empty {
      padding: 38px 24px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .empty-image {
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
      }
    }

    .new-job {
      margin-bottom: 16px;
      display: flex;

      &__image {
        margin-right: 12px;
        width: 32px;
        height: 32px;
        flex: 32px 0 0;
      }

      &__info {
        flex: 1;

        a {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $main;
          transition: color 0s;

          &:hover {
            color: $link;
            text-decoration: underline;
          }
        }

        .typography-caption {
          color: $lightgray;
        }

        .status-default {
          margin-top: 4px;

          & + .typography-caption {
            margin-top: 4px;
          }
        }

        .openings-info {
          display: flex;
          align-items: center;

          .typography-caption {
            margin: 4px 0 0 12px;
          }
        }
      }

      &__action {
        margin-left: 12px;
        flex: 0 0 150px;

        .button-default {
          width: 100%;
        }
      }
    }

    .show-more {
      display: none;
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }

  @media only screen and (max-width: $screen-mobile-max) {
    height: auto;

    &__header {
      padding: 24px 16px;
      font-size: 16px;
      line-height: 20px;
    }

    &__content {
      padding: 16px 16px 28px;
      overflow: visible;
    }

    .new-job {
      padding-top: 36px;
      flex-direction: column;
      position: relative;

      &__image {
        position: absolute;
        top: 0;
        left: 0;
      }

        &__action {
        margin: 16px 0 0;
        flex: none;
      }
    }

    .show-more {
      margin-top: 20px;
      display: flex;
    }

    &:not(.allShown) .new-jobs__content .new-job:nth-child(n+4) {
      display: none;
    }
  }
}