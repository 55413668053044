@import "src/styles/colors";
@import "src/styles/breakpoints";

.popup-cancel-interview {
  .info-block {
    margin-bottom: 16px;

    .typography-label {
      margin-bottom: 4px;
      font-weight: 400;
      color: $lightgray;
    }

    .typography-text {
      color: $main;
    }

    a {
      color: $link;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {}

  @media only screen and (max-width: $screen-mobile-max) {
    .popup-default__footer {
      .ant-btn-default {
        padding: 8px;
      }
    }
  }
}
