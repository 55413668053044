@import "src/styles/colors";
@import "src/styles/breakpoints";

.job-assigned {

  &_mobile {
    display: none;
  }

  &__empty {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      margin-bottom: 40px;
      width: 124px;
      height: 124px;
    }
    .typography {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $main;
    }
  }
  &__loading {
    padding: 24px;
  }

  .card-default {
    margin: 0;
  }

  .candidate-link {
    .typography-text {
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 16px;
      color: $main;
    }
    .typography-caption {
      font-size: 12px;
      line-height: 16px;
      color: $placeholder;
    }
    &:hover {
      .typography-text,
      .typography-caption {
        color: $link;
      }
    }
    &:active {
      .typography-text,
      .typography-caption {
        color: $accent_80;
      }
    }
  }

  @media only screen and (max-width: $screen-tablet-max) and (min-width: $screen-tablet-min) {
  }

  @media only screen and (max-width: $screen-mobile-max) {
    .table-default-antd {
      display: none;
    }

    &_mobile {
      display: block;

      .assignment-item {
        padding: 16px;
        border-bottom: 1px solid $border;

        &__row {
          margin-bottom: 8px;
          display: flex;

          .title {
            width: 118px;
            padding-right: 8px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}