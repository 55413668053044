@import "src/styles/colors";

.job-interview {
  position: relative;

  .typography-h2 {
    margin-bottom: 24px;
  }

  &__add-button svg path {
    fill: $accent_70;
  }

  &__actions {
    position: absolute !important;
    right: 0;
    top: 0;
  }

  .confirm-interview {
    position: absolute;
    top: 0;
    right: 52px;
  }

  .interview-item {
    display: flex;

    &__icon {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      flex: 0 0 32px;
    }
    &__info {
      flex: 1;
      overflow: hidden;

      .title {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $lightgray;
      }

      .info-link {
        color: $link;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .ant-divider {
    margin: 32px 0;
  }
}